<template>
    <div class="animated fadeIn">
        <b-form v-on:submit.prevent
                novalidate
                autocomplete="off">
            <b-card>
                <div slot="header">
                    <h2>Change Your Password</h2>
                </div>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label for="currentPassword" class="eden__label__required">Current Password</label>
                            <b-input-group>

                                <b-form-input v-model="form.currentPassword"
                                              type="password"
                                              ref="form.currentPassword"
                                              :state="$v.form.currentPassword.$dirty ? !$v.form.currentPassword.$error : null"
                                              aria-describedby="currentPasswordFeedback"
                                              placeholder="Current Password"></b-form-input>

                                <b-form-invalid-feedback id="currentPasswordFeedback">
                                    <div v-if="!$v.form.currentPassword.required">This is a required field.</div>
                                </b-form-invalid-feedback>

                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label for="newPassword" class="eden__label__required">Create a New Password - Please have at least eight characters, and include a capital letter, number and a special character.</label>
                            <b-input-group>

                                <b-form-input v-model="form.newPassword"
                                              type="password"
                                              ref="form.newPassword"
                                              :state="$v.form.newPassword.$dirty ? !$v.form.newPassword.$error : null"
                                              aria-describedby="passwordFeedback"
                                              placeholder="New Password"></b-form-input>

                                <b-form-invalid-feedback id="passwordFeedback">
                                    <div v-if="!$v.form.newPassword.required">This is a required field.</div>
                                    <div v-if="$v.form.newPassword.required && !$v.form.newPassword.minLength">Your password needs at least 8 characters.</div>
                                    <div v-if="$v.form.newPassword.required && !$v.form.newPassword.containsUppercase">Your password needs a capitol letter.</div>
                                    <div v-if="$v.form.newPassword.required && !$v.form.newPassword.containsLowercase">Your password needs a lower case letter.</div>
                                    <div v-if="$v.form.newPassword.required && !$v.form.newPassword.containsNumber">Your password needs a number.</div>
                                    <div v-if="$v.form.newPassword.required && !$v.form.newPassword.containsSpecial">Your password needs a special character.</div>
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label for="newPassword2" class="eden__label__required">Confirm Your New Password</label>
                            <b-input-group>

                                <b-form-input v-model="form.newPassword2"
                                              type="password"
                                              ref="form.newPassword2"
                                              :state="$v.form.newPassword2.$dirty ? !$v.form.newPassword2.$error : null"
                                              aria-describedby="password2Feedback"
                                              placeholder="Re-enter Your Password"></b-form-input>

                                <b-form-invalid-feedback id="password2Feedback">
                                    <div v-if="!$v.form.newPassword2.required">This is a required field.</div>
                                    <div v-if="$v.form.newPassword.required && !$v.form.newPassword.sameAs">This must match with your new password.</div>
                                </b-form-invalid-feedback>

                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-4">
                    <b-col cols="12" xl="2" lg="3" md="6">
                        <b-form-group>
                            <b-button v-on:click="changePassword"
                                      ref="save"
                                      id="save"
                                      variant="primary"
                                      class="w-100">
                                <i class="fa fa-user mr-2"></i>
                                Change Password
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-form>
    </div>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import {
        required,
        minLength,
        sameAs,
  } from 'vuelidate/lib/validators'
  import PasswordToggleInput from '@shared/components/PasswordToggleInput'

  export default {
    mixins: [
      validationMixin,
    ],

    components: {
      PasswordToggleInput,
    },

    data() {
        return {
        form: {
          currentPassword: null,
          newPassword: null,
          newPassword2: null,
        },
      }
    },

    validations: {
      form: {
        currentPassword: { required },
        newPassword: {
              required,
              minLength: minLength(8),
              containsUppercase: function (newPassword) {
                  return /[A-Z]/.test(newPassword)
              },
              containsLowercase: function (newPassword) {
                  return /[a-z]/.test(newPassword)
              },
              containsNumber: function (newPassword) {
                  return /[0-9]/.test(newPassword)
              },
              containsSpecial: function (newPassword) {
                  return /[#?!@$%^&*-]/.test(newPassword)
              },
        },
            newPassword2: {
                required,
                sameAs: sameAs('newPassword'),
            },
      },
        },

        methods: {

      async changePassword() {
        // Form validation
        this.$v.form.$touch()
                console.log(this.$v.form)
        if (this.$v.form.$anyError) {
          return
        }

        const loader = this.$loading.show()

        try {
          const { data } = await this.$request({
            url: '/api/user/change-password',
            method: 'put',
            data: this.form,
          })
          this.$toasted.global.success({
            message: 'Your password has been changed! Please log in again.',
          })
          this.$router.push('/login')
        } catch (e) {
          this.$log.error('Error resetting user password', e)
          this.$toasted.global.error({
            message: e.response?.data?.errors || e.response?.data || e.message,
          })
          return
        } finally {
          loader.hide()
        }
      },
    },   
  }
</script>
